import { format } from "date-fns"
import React, { useEffect, useState } from "react"
import { useLoaderData } from "react-router-dom"
import { supabase } from "../lib/supabaseClient"

export default function ArtistProfile() {
	const artist = useLoaderData()

	const [events, setEvents] = useState([])
	const [stages, setStages] = useState([])
	async function fetchEvents(id) {
		const { data, error } = await supabase
			.from("events")
			.select()
			.eq("artist", id)
			.neq("announced", false)

		if (error) {
			console.error(error)
		} else {
			setEvents(data)
		}
	}

	async function fetchStages() {
		const { data, error } = await supabase.from("stages").select("id, name")
		if (error) {
			console.error(error)
		} else {
			setStages(data)
		}
	}

	useEffect(() => {
		fetchStages()
	}, [])

	useEffect(() => {
		fetchEvents(artist.id)
	}, [artist])

	useEffect(() => {
		console.log(stages)
	}, [stages])

	return (
		<div className="artist-profile">
			<div
				className="hero"
				style={{
					backgroundColor: artist.color,
					backgroundImage: artist.img ? `url(${artist.img})` : "none",
				}}>
				<div className="close"></div>
			</div>

			<div className="info">
				<div className="container">
					<h1 style={{ color: artist.color }}>{artist.name}</h1>
					<div className="bio">
						Lorem ipsum dolor sit amet consectetur adipisicing elit. Distinctio
						temporibus doloremque doloribus quod rerum dolores laudantium sint
						ex. A quos ducimus eaque esse, aspernatur ullam facilis asperiores
						porro soluta rem!
					</div>
				</div>
			</div>

			<div className="container">
				<div className="events">
					{events.length > 0 &&
						events.map((ev) => {
							const stage = stages.find((st) => st.id === ev.stage)
							return (
								<div key={ev.id} className="event">
									<div>
										<div className="day">
											{format(new Date(ev.start_time), "EEEE")}
										</div>
										<div className="name" style={{ color: artist.color }}>
											{stage && stage.name}
										</div>
										<div className="times">
											{format(new Date(ev.start_time), "HH:mm")} -{" "}
											{format(new Date(ev.end_time), "HH:mm")}
										</div>
									</div>
									<div className="star"></div>
								</div>
							)
						})}
				</div>
			</div>
		</div>
	)
}
