import React from "react"
import ReactDOM from "react-dom/client"

// Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css"
// Bootstrap Bundle JS
import "bootstrap/dist/js/bootstrap.bundle.min"

import "./styles/index.scss"
// import reportWebVitals from "./reportWebVitals"
import App from "./App"

import { library } from "@fortawesome/fontawesome-svg-core"
import {
	faCalendarDays,
	faHome,
	faNewspaper,
	faStar as faStarFull,
	faUser,
} from "@fortawesome/free-solid-svg-icons"
import { faStar } from "@fortawesome/free-regular-svg-icons"
library.add(faCalendarDays, faHome, faNewspaper, faStar, faStarFull, faUser)

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
