import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
	differenceInMinutes,
	format,
	fromUnixTime,
	getHours,
	getMinutes,
} from "date-fns"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { supabase } from "../lib/supabaseClient"

export default function CalendarEvent({
	artist_id,
	timeStart,
	timeEnd,
	favourited,
}) {
	const times = 4.5
	const [isFavourited, setIsFavourited] = useState(false)

	let start = fromUnixTime(timeStart)
	let end = fromUnixTime(timeEnd)
	let startH = getHours(start)
	let startM = getMinutes(start)
	let duration = differenceInMinutes(end, start)

	const [artist, setArtist] = useState()
	async function fetchArtist(id) {
		const { data, error } = await supabase
			.from("artists")
			.select()
			.eq("id", id)
			.single()
		if (error) {
			console.error(error)
		} else {
			setArtist(data)
		}
	}

	useEffect(() => {
		fetchArtist(artist_id)
	}, [artist_id])

	useEffect(() => {
		favourited ? setIsFavourited(true) : setIsFavourited(false)
	}, [favourited])

	if (!artist) return
	return (
		<div
			className={`calendar-card`}
			style={{
				left: startH * 60 * times - 10 * 60 * times + startM * times,
				width: duration * times,
				backgroundColor: artist.color,
			}}>
			<div>
				<Link to={`../../artist/${artist_id}`} title={artist.name}>
					<h4 title={artist.name}>{artist.name}</h4>
					<div className="times">
						{format(start, "H:mm")}-{format(end, "H:mm")}
					</div>
				</Link>
			</div>
			<div className="favourite" onClick={() => setIsFavourited(!isFavourited)}>
				{isFavourited ? (
					<FontAwesomeIcon icon={`fas fa-star`} />
				) : (
					<FontAwesomeIcon icon={`fa-regular fa-star`} />
				)}
			</div>
		</div>
	)
}
