export default function Header() {
	return (
		<header className="bd-header bg-dark py-2 d-flex align-items-stretch border-bottom border-dark text-white">
			<div className="container d-flex align-items-center justify-content-between">
				<h1 className="d-flex align-items-center fs-4 text-white mb-0">
					<img
						src="https://upload.wikimedia.org/wikipedia/commons/4/4c/Creative_Artists_Agency_logo_%28no_text%29.svg"
						alt="CAA"
						width={100}
					/>
				</h1>

				<div className="text-end">
					<h2 className="mb-0">C2C 2023</h2>
				</div>
			</div>
		</header>
	)
}
