import {
	createBrowserRouter,
	createRoutesFromElements,
	Navigate,
	Route,
	RouterProvider,
} from "react-router-dom"
import "./styles/App.scss"
import { supabase } from "./lib/supabaseClient"
import DashboardPage from "./pages/Dashboard"
import NewsPage from "./pages/News"
import TimetablePage, { Timetable } from "./pages/Timetable"
import ArtistProfile from "./pages/ArtistProfile"

export async function fetchArtists() {
	const { data, error } = await supabase.from("artists").select()
	if (error) {
		console.error(error)
	} else {
		return data
	}
}

export async function fetchEvents() {
	const { data, error } = await supabase.from("events").select()
	if (error) {
		console.error(error)
	} else {
		return data
	}
}

export async function fetchStages() {
	const { data, error } = await supabase.from("stages").select()
	if (error) {
		console.error(error)
	} else {
		return data
	}
}

async function loader() {
	const artists = await fetchArtists()
	const events = await fetchEvents()
	const stages = await fetchStages()
	const data = { artists, events, stages }
	return data
}

function App() {
	const router = createBrowserRouter(
		createRoutesFromElements(
			<>
				<Route path={"/"} element={<DashboardPage />}>
					<Route index element={<Navigate to={"schedule"} replace />} />
					<Route
						loader={fetchArtists}
						path={"schedule"}
						element={<TimetablePage />}>
						<Route index element={<Navigate to={"friday"} replace />} />
						<Route loader={loader} path=":day" element={<Timetable />} />
					</Route>
					<Route path="news" element={<NewsPage />} />
					<Route
						loader={async ({ params }) => {
							const { data } = await supabase
								.from("artists")
								.select()
								.eq("id", params.id)
								.single()
							if (!data) {
								return
							} else {
								return data
							}
						}}
						path="artist/:id"
						element={<ArtistProfile />}
					/>
				</Route>
			</>
		)
	)

	return <RouterProvider router={router} />
}

export default App
