import { isSameDay, getUnixTime } from "date-fns"
import React, { useEffect, useState } from "react"
import {
	NavLink,
	Outlet,
	useLoaderData,
	useMatch,
	useNavigation,
	useParams,
} from "react-router-dom"
import CalendarEvent from "../components/CalendarEvent"
import Spinner from "../components/Spinner"

export function CalendarRow({ children }) {
	return (
		<div className="calendar-row">
			<div className="hours">
				<div className="hour" data-hour="10" />
				<div className="hour" data-hour="11" />
				<div className="hour" data-hour="12" />
				<div className="hour" data-hour="13" />
				<div className="hour" data-hour="14" />
				<div className="hour" data-hour="15" />
				<div className="hour" data-hour="16" />
				<div className="hour" data-hour="17" />
				<div className="hour" data-hour="18" />
				<div className="hour" data-hour="19" />
				<div className="hour" data-hour="20" />
				<div className="hour" data-hour="21" />
				<div className="hour" data-hour="22" />
				<div className="hour" data-hour="23" />
				<div className="hour" data-hour="00" />
			</div>
			{children}
		</div>
	)
}

export function Timetable() {
	const { day } = useParams()
	const data = useLoaderData()
	const { events, stages } = data

	const [eventsByStage, setEventsByStage] = useState([])

	useEffect(() => {
		const fri = new Date("2023-03-10")
		const sat = new Date("2023-03-11")
		const sun = new Date("2023-03-12")

		let eventsGroupedByStage = []

		// create stages
		events.forEach((ev) => {
			let newStage = {
				stage_id: ev.stage,
				friday: [],
				saturday: [],
				sunday: [],
			}
			if (
				eventsGroupedByStage.length > 0 &&
				eventsGroupedByStage.find((st) => st.stage_id === ev.stage)
			)
				return
			eventsGroupedByStage.push(newStage)
		})

		if (!eventsGroupedByStage.length > 0) return

		// populate stages
		events.forEach((ev, i) => {
			if (!ev.start_time) return
			let startTime = new Date(ev.start_time)
			let endTime = new Date(ev.end_time)
			let newEvent = {
				artist_id: ev.artist,
				timeStart: getUnixTime(startTime),
				timeEnd: getUnixTime(endTime),
			}
			if (isSameDay(startTime, fri)) {
				let selectedStage = eventsGroupedByStage.find(
					(st) => st.stage_id === ev.stage
				)
				let selectedDay = selectedStage.friday ? selectedStage.friday : []
				selectedDay.push(newEvent)
			} else if (isSameDay(startTime, sat)) {
				let selectedStage = eventsGroupedByStage.find(
					(st) => st.stage_id === ev.stage
				)
				let selectedDay = selectedStage.saturday ? selectedStage.saturday : []
				selectedDay.push(newEvent)
			} else if (isSameDay(startTime, sun)) {
				let selectedStage = eventsGroupedByStage.find(
					(st) => st.stage_id === ev.stage
				)
				let selectedDay = selectedStage.sunday ? selectedStage.sunday : []
				selectedDay.push(newEvent)
			}
		})

		setEventsByStage(eventsGroupedByStage)
	}, [events])

	return (
		<div className="timetable">
			<div className="stages">
				{eventsByStage.length > 0 &&
					eventsByStage
						.filter((ev) => ev[day].length > 0)
						.map((ev) => {
							let currentStage = stages.find((st) => st.id === ev.stage_id)
							return (
								<div key={ev.stage_id} className="stage-name">
									<div>
										<h3>{currentStage.name}</h3>
									</div>
								</div>
							)
						})}
			</div>
			<div className="calendar">
				{eventsByStage.length > 0 &&
					eventsByStage
						.filter((ev) => ev[day].length > 0)
						.map((stage, i) => {
							return (
								<CalendarRow key={i}>
									{stage[day].map((d, i) => {
										return (
											<CalendarEvent
												key={i}
												artist_id={d.artist_id}
												timeStart={d.timeStart}
												timeEnd={d.timeEnd}
											/>
										)
									})}
								</CalendarRow>
							)
						})}
			</div>
		</div>
	)
}

export default function TimetablePage() {
	const navigation = useNavigation()
	const artists = useLoaderData()

	const match1 = useMatch("/schedule/saturday")
	const match2 = useMatch("/schedule/sunday")
	let m = match1 ? 1 : match2 ? 2 : 0
	return (
		<section className="container py-4">
			<h3>Confirmed Act Schedules</h3>
			<nav className="days-nav">
				<ul>
					<li>
						<NavLink to={"./friday"}>Friday</NavLink>
					</li>
					<li>
						<NavLink to={"./saturday"}>Saturday</NavLink>
					</li>
					<li>
						<NavLink to={"./sunday"}>Sunday</NavLink>
					</li>
					<li className="line" style={{ left: `${33.33 * m}%` }} />
				</ul>
			</nav>

			{navigation.state === "loading" ? (
				<>
					<div class="timetable">
						<div class="stages">
							<div class="stage-name"></div>
							<div class="stage-name"></div>
							<div class="stage-name"></div>
							<div class="stage-name"></div>
						</div>
						<div class="calendar">
							<CalendarRow />
							<CalendarRow />
							<CalendarRow />
							<Spinner />

							<CalendarRow />
						</div>
					</div>
				</>
			) : (
				<Outlet />
			)}

			<div className="legend py-2">
				<div className="row">
					<div className="col">
						{artists
							.filter((artist, i) => {
								return i < artists.length / 2
							})
							.map((artist, i) => (
								<div key={i} className="legend-item">
									<div
										className="color"
										style={{ backgroundColor: artist.color }}
									/>
									<div className="name">{artist.name}</div>
								</div>
							))}
					</div>
					<div className="col">
						{artists
							.filter((artist, i) => {
								return i >= artists.length / 2
							})
							.map((artist, i) => (
								<div key={i} className="legend-item">
									<div
										className="color"
										style={{ backgroundColor: artist.color }}
									/>
									<div className="name">{artist.name}</div>
								</div>
							))}
					</div>
				</div>
			</div>
		</section>
	)
}
