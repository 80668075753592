import { useEffect } from "react"

const TwitterContainer = () => {
	useEffect(() => {
		const anchor = document.createElement("a")
		anchor.setAttribute("class", "twitter-timeline")
		anchor.setAttribute("data-theme", "light")
		anchor.setAttribute("data-tweet-limit", "5")
		anchor.setAttribute("data-chrome", "noheader nofooter noborders")
		anchor.setAttribute("href", "https://twitter.com/C2Cfestival")
		document.getElementsByClassName("twitter-embed")[0].appendChild(anchor)

		const script = document.createElement("script")
		script.setAttribute("src", "https://platform.twitter.com/widgets.js")
		document.getElementsByClassName("twitter-embed")[0].appendChild(script)
	}, [])

	return (
		<section className="twitterContainer">
			<div className="twitter-embed" />
		</section>
	)
}

export default function NewsPage() {
	return (
		<div className="container py-5">
			<TwitterContainer />
		</div>
	)
}
