import { Outlet } from "react-router-dom"
import Footer from "../components/Footer"
import Header from "../components/Header"

export default function DashboardPage() {
	return (
		<>
			<div className="site-container" style={{ height: "100%" }}>
				<Header />
				<div className="main-content">
					<Outlet />
				</div>
				<Footer />
			</div>
		</>
	)
}
