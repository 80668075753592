import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { NavLink } from "react-router-dom"

export default function Footer() {
	return (
		<div className="footer-nav">
			<div>
				<NavLink to={`${"/schedule"}`} className={"link"}>
					<FontAwesomeIcon icon={`fas fa-calendar-days`} />
				</NavLink>
			</div>
			<div>
				<NavLink to={"/news"} className={"link"}>
					<FontAwesomeIcon icon={`fas fa-newspaper`} />
				</NavLink>
			</div>
			{/* <div>
				<NavLink to={`${"/"}`} className={"link"}>
					<FontAwesomeIcon icon="fas fa-user" />
				</NavLink>
			</div> */}
		</div>
	)
}
